import React from "react";
// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import "./App.css";
import TabConfig from "./TabConfig";
import { useTeams } from "@microsoft/teamsfx-react";
import { app } from "@microsoft/teams-js";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */


const generateRoomPlannerQuickLinkProperties = (context) => {

  const RoomPlannerQuickLinkProperties = {};
  const propertiesHolder = context?.page?.subPageId;
  
  if(propertiesHolder) {

    try{

      const decodedProperties = atob(propertiesHolder);
      const parsedProperties = JSON.parse(decodedProperties);

      RoomPlannerQuickLinkProperties.seatId = parsedProperties.seatId;
      RoomPlannerQuickLinkProperties.roomId = parsedProperties.roomId;
      RoomPlannerQuickLinkProperties.book = parsedProperties.book;
      RoomPlannerQuickLinkProperties.showInfo = parsedProperties.showInfo;
      RoomPlannerQuickLinkProperties.startHour = parsedProperties.startHour;
      RoomPlannerQuickLinkProperties.startMinute = parsedProperties.startMinute;
      RoomPlannerQuickLinkProperties.startSecond = parsedProperties.startSecond;
      RoomPlannerQuickLinkProperties.endHour = parsedProperties.endHour;
      RoomPlannerQuickLinkProperties.endMinute = parsedProperties.endMinute;
      RoomPlannerQuickLinkProperties.endSecond = parsedProperties.endSecond;
      RoomPlannerQuickLinkProperties.additionalInformation = parsedProperties.additionalInformation;
      RoomPlannerQuickLinkProperties.dogBooked = parsedProperties.dogBooked;

    } catch (error) {
      console.error("Error parsing quick link properties from context: " + error.message);
    
    }

  }

  return RoomPlannerQuickLinkProperties;

};


const storeRoomPlannerQuickLinkProperties = (RoomPlannerQuickLinkProperties) => {

  const RoomPlannerQuickLinkPropertiesJSON = JSON.stringify(RoomPlannerQuickLinkProperties);

  sessionStorage.setItem('RoomPlannerQuickLinkProperties', RoomPlannerQuickLinkPropertiesJSON);

};


export default function App() {
  const { theme } = useTeams({})[0];

  app.initialize().then(
    () => {
      app.getContext().then(

        (context) => {

          const RoomPlannerQuickLinkProperties = generateRoomPlannerQuickLinkProperties(context);
          storeRoomPlannerQuickLinkProperties(RoomPlannerQuickLinkProperties);
          
        }

      );
    }
  );

  return (
    <FluentProvider
      theme={
        theme || {
          ...teamsLightTheme,
          colorNeutralBackground3: "transparent",
        }
      }
      style={{ background: "transparent" }}
    >
      <Router>
        <Routes>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/tab" element={<Tab />} />
          <Route path="/config" element={<TabConfig />} />
          <Route path="*" element={<Navigate to={"/tab"} />}></Route>
        </Routes>
      </Router>
    </FluentProvider>
  );
}
