import React from "react";
/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
class Privacy extends React.Component {
  render() {
    return (
      <div>
        <h1>Privacy Statement</h1>
        <p>
          At RoomPlanner Department, we value your privacy and are committed to protecting your personal information. This Privacy Statement explains how we collect, use, and disclose your information when you use our RoomPlanner application.
        </p>
        <p>
          We may collect certain information from you, such as your name, email address, and usage data, in order to provide you with a personalized experience and improve our services. We will never sell your information.
        </p>
        <p>
          By using RoomPlanner, you consent to the collection and use of your information as described in this Privacy Statement. If you have any questions or concerns about our privacy practices, please contact us.
        </p>
      </div>
    );
  }
}

export default Privacy;
