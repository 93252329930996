const defaultLanguage = 'en';

const translations = {

    en: {

      TabWelcomeTitle: 'Welcome to the RoomPlanner app!',
      TabWelcomeDescriptionText: 'Please click on "Start RoomPlanner" and consent permissions to use the app.',
      TabLoginButtonText: 'Start RoomPlanner',

    },

    de: {

      TabWelcomeTitle: 'Willkommen in der RoomPlanner App!',
      TabWelcomeDescriptionText: 'Bitte klicken Sie auf "RoomPlanner starten" und geben Sie die Berechtigungen zur Verwendung der App frei.',
      TabLoginButtonText: 'RoomPlanner starten',

    }

};


export { translations, defaultLanguage };