import { translations, defaultLanguage } from "./translations";


const getCurrentLanguage = () => {

  const currentLanguagePrefix = (navigator.language || navigator.userLanguage).split('-')[0];
  const currentLanguage = translations[currentLanguagePrefix] ? currentLanguagePrefix : defaultLanguage;

  return currentLanguage;

};


const translate = (translationKey) => {

  const currentLanguage = getCurrentLanguage();
  const translation = translations[currentLanguage][translationKey];

  return translation;

};


const translateMultiple = (translationKeys) => {

  const currentLanguage = getCurrentLanguage();
  let translatedTexts = {};

  translationKeys.forEach(

    _translationKey => {

      const _translation = translations[currentLanguage][_translationKey];
      translatedTexts[_translationKey] = _translation;
    
    }

  );
  
  return translatedTexts;

};



export { getCurrentLanguage, translate, translateMultiple };