import React from "react";


/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */




class TermsOfUse extends React.Component {


  render() {

    return (

      <div>

        <h1>Terms of Use</h1>

        <p>
          By using this RoomPlanner application, you agree to the following terms of use:
        </p>

        <ul>

          <li>
            This application is provided for informational purposes only and does not
            guarantee the accuracy or completeness of the information provided.
          </li>

          <li>
            The RoomPlanner application is not responsible for any damages or losses
            incurred as a result of using the application.
          </li>

          <li>
            Users are solely responsible for the content they upload or share through
            the RoomPlanner application.
          </li>

          <li>
            The RoomPlanner application reserves the right to modify or terminate the
            service at any time without prior notice.
          </li>

          <li>
            Users must comply with all applicable laws and regulations while using the
            RoomPlanner application.
          </li>

        </ul>

        <p>
          By continuing to use the RoomPlanner application, you acknowledge and agree to
          these terms of use.
        </p>

      </div>

    );

  }


}

export default TermsOfUse;
