
const apiScopes = [

    "ChannelMessage.Send",
    "Calendars.ReadWrite",
    "User.ReadWrite",
    "User.Read",
    "User.ReadBasic.All",
    "Calendars.ReadBasic",
    "Calendars.ReadWrite.Shared",

];

//By setting requiredResourceAccess (or maybe? preAuthorizedApplications?) in aad.manifest.json, scopes listed there will be set without needing user consent.


export { apiScopes };